import * as React from 'react'
import classNames from "classnames"
import { PortableText } from '@portabletext/react';
import PropTypes from "prop-types"

const Credentials = ({ capabilities, offices, industry, recognition }) => {
  const sortedCapabilities = capabilities.sort((a, b) => (a.order > b.order) ? 1 : -1);
  const sortedOffices = offices.sort((a, b) => (a.city > b.city) ? 1 : -1);


  return (
    <section
      className={classNames('container creds', {
        // 'creds--capabilities-long': capabilities?.length > 4,
        'creds--hasRecognition': recognition,
        'creds--noRecognition': !recognition,
        'creds--hasIndustry': industry.length > 0
      })}
    >
      <div className="creds__category creds__category--capabilities">
        <h2 className="descriptor"> { capabilities.length > 1 ? 'Capabilities' : 'Capability' }</h2>
        <ul className="creds__cred-list">
          { sortedCapabilities.map((capability) => (
            <li key={capability.id} className="creds__cred">{ capability.name }</li>
          ))}
        </ul>
      </div>

      <div className='creds__category creds__category--office'>
        <h2 className="descriptor">{ offices.length > 1 ? 'Offices' : 'Office' }</h2>
        <ul className='creds__cred-list'>
          { sortedOffices.map((office) => (
            <li key={office.id} className="creds__cred">{ office.city }</li>
          ))}
        </ul>
      </div>

      {industry.length > 0 && (
        <div className='creds__category creds__category--industry'>
          <h2 className="descriptor"> { industry.length > 1 ? 'Industries' : 'Industry' }</h2>
          <ul className='creds__cred-list'>
            { industry.sort().map(tag => (
              <li key={tag} className="creds__cred">{ tag }</li>
            ))}
          </ul>
        </div>
        )}

      {recognition && (
        <div className='creds__category creds__category--recognition'>
          <h2 className="descriptor">Recognition</h2>
          <div className='creds__category--recognition-rich'>
            <PortableText value={recognition} />
          </div>
        </div>
      )}        

    </section>
  )
}

Credentials.propTypes = {
  capabilities: PropTypes.array.isRequired, 
  offices: PropTypes.array.isRequired, 
  industry: PropTypes.array, 
  recognition: PropTypes.array
}

export default Credentials